import {BaseCartController} from '../../domain/controllers/BaseCartController';
import {settingsParams} from './settingsParams';
import {ICartStyleSettings} from '../../types/app.types';
import {SIDE_CART_ORIGIN} from '../../common/constants';
import {getFreeTextKeys} from '../../domain/utils/settings.utils';
import {SPECS} from '../../domain/specs';
import styleParams from './stylesParams';
import {StyleParamType} from '@wix/tpa-settings';

const SIDE_CART_LAYOUT = 'sideCart';

export class SideCartController extends BaseCartController {
  protected getOrigin() {
    return SIDE_CART_ORIGIN;
  }

  protected getCartLayout(): 'cart' | 'sideCart' {
    return SIDE_CART_LAYOUT;
  }

  protected getDefaultSettings() {
    const isAddFieldsToViewCartPageBIEnabled = this.siteStore.experiments.enabled(SPECS.AddFieldsToViewCartPageBI);
    return isAddFieldsToViewCartPageBIEnabled
      ? ({
          booleans: Object.keys(styleParams)
            .filter((param) => styleParams[param].type === StyleParamType.Boolean)
            .reduce((acc, key) => {
              acc[key] = styleParams[key].getDefaultValue();
              return acc;
            }, {}),
        } as unknown as ICartStyleSettings)
      : ({} as ICartStyleSettings);
  }

  /* istanbul ignore next */
  public exports() {
    return {};
  }

  /* istanbul ignore next */
  public getFreeTexts(): string[] {
    return this.siteStore.experiments.enabled(SPECS.UseGetSettingsWithDefaults) ? [] : getFreeTextKeys(settingsParams);
  }
}
